<template>
  <v-card flat tile>
    <v-window v-model="onboarding">
      <v-window-item v-for="(a, index) in project.apps" :key="index">
        <app-card :project="project" :boilerplates="boilerplates" :clusters="clusters" :types="types" :app="a" v-on="$listeners" />
      </v-window-item>
    </v-window>

    <v-card-actions class="justify-space-between">
      <v-btn
        text
        @click="prev"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-item-group
        v-model="onboarding"
        class="text-center"
        mandatory
      >
        <v-item
          v-for="n in project.apps.length"
          :key="`btn-${n}`"
          v-slot="{ active, toggle }"
        >
          <v-btn
            :input-value="active"
            icon
            @click="toggle"
            x-small
          >
            <v-icon x-small>circle</v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-btn
        text
        @click="next"
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppCard from './AppCard.vue'

export default {
  props: {
    project: {
      type: Object,
      require: true
    },
    boilerplates: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    clusters: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    types: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    }
  },
  components: {
    AppCard
  },
  data: () => ({
    apps: [],
    onboarding: 0
  }),
  mounted () {
    this.refresh()
  },
  methods: {
    refresh () {

    },
    next () {
      this.onboarding = this.onboarding + 1 === this.apps.length ? 0 : this.onboarding + 1
    },
    prev () {
      this.onboarding = this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1
    }
  },
  watch: {
    onboarding (newValue) {
      this.$emit('highlight', this.project.unix, newValue)
    }
  }
}
</script>
