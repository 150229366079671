<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title :class="color + ' white--text px-3 mb-6'">
        <v-icon large left dark>{{ icon }}</v-icon>
        <span class="headline" v-show="$vuetify.breakpoint.smAndUp">{{ label }}</span>
        <v-spacer />
        <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text">{{ repository + '@' + stage }}</v-chip>
      </v-card-title>
      <v-card-text class="text-h5" v-show="$vuetify.breakpoint.xsOnly">{{ label }}</v-card-text>
      <v-card-text class="text-body2">{{ description }}</v-card-text>
      <v-alert type="error" :value="error" transition="scale-transition" class="mt-0 mx-4">
        {{ message }}
      </v-alert>
      <v-card-actions class="pb-4">
        <v-btn x-large class="white--text" color="warning" block depressed @click="revalidate()" target="_blank" :disabled="status !== 'INVALID' || (stage === 'release' && !maintainer)" :loading="loading">
          <v-icon large>rotate_right</v-icon>
          Revalidar
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm-wrapper ref="confirm" />
    <dialog-wrapper ref="info" />
  </v-dialog>
</template>

<script>
import axios from 'axios'

import EmbrapaHelper from '@/helpers/embrapa'
import ErrorHelper from '@/helpers/error'

import ConfirmWrapper from '@/components/ConfirmDialog.vue'

export default {
  mixins: [
    EmbrapaHelper,
    ErrorHelper
  ],
  components: {
    ConfirmWrapper
  },
  data: () => ({
    dialog: false,
    error: false,
    message: '',
    loading: false,
    repository: '',
    stage: '',
    cluster: '',
    status: 'DRAFT',
    color: 'info',
    label: '',
    icon: '',
    description: '',
    maintainer: false
  }),
  beforeMount () {
    this.reload()

    this.user = this.$localStorage.get('user')
  },
  mounted () {
    if (this.user.authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  methods: {
    open (app, stage, metadata, maintainer) {
      this.reload()

      this.repository = app.repository
      this.stage = stage
      this.cluster = app.stages[stage].cluster
      this.status = app.stages[stage].status
      this.color = metadata.color
      this.label = metadata.label
      this.icon = metadata.icon
      this.description = metadata.description
      this.maintainer = maintainer

      this.dialog = true
    },
    reload () {
      this.repository = ''
      this.stage = ''
      this.cluster = ''
      this.status = 'DRAFT'
      this.color = 'info'
      this.label = ''
      this.icon = ''
      this.description = ''
      this.error = false
      this.message = ''
      this.loading = false
      this.maintainer = false
    },
    revalidate () {
      this.$refs.confirm.open(
        'Revalidar a build?',
        'A build "' + this.repository + '@' + this.stage + '" será REVALIDADA no servidor/cluster "' + this.cluster + '"!',
        { color: 'warning', width: 500, persistent: true }).then((confirm) => {
        if (confirm) {
          this.loading = true

          this.error = false
          this.message = ''

          axios.post(process.env.VUE_APP_API + '/build/revalidate/' + this.repository + '/' + this.stage, {}, { headers: this.headers }).then(response => {
            this.$emit('refresh')

            this.dialog = false

            this.reload()
          }).catch(error => {
            this.error = true
            this.message = this.errorMessage(error)

            this.loading = false
          })
        }
      })
    },
    cancel () {
      this.reload()

      this.dialog = false
    }
  }
}
</script>
