<template>
  <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title
        :class="!accept ? 'error white--text' : 'success white--text'"
      >
        <span class="headline">{{ accept ? 'Criar App' : 'Atenção!' }}</span>
        <v-spacer />
        <v-btn icon class="hidden-sm-and-up" color="white" @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text class="pt-4 pb-0 subtitle-1">
            Esta ferramenta irá criar um novo app, com base em um modelo (<i>boilerplate</i>) aderente aos processos automatizados de DevOps
            (desenvolvimento, integração, teste e entrega contínuos).
          </v-card-text>
          <v-card-text class="pt-4 pb-0 subtitle-1">
            Este modelo irá conter uma estrutura de diretórios básica, baseada no arcabouço (<i>framework</i>) do modelo. Além disso, uma
            pasta "<span style="font-family: monospace;" class="font-weight-bold">.embrapa</span>" na raiz do app irá conter as configurações essenciais requeridas
            pelos <i>pipelines</i> de CI/DI do <strong>Embrapa I/O</strong>. Ao final, o app poderá ser clonado por meio do
            <a href="https://git.embrapa.io" target="_blank">GitLab</a> do App e instanciado em ambiente de desenvolvimento
            (utilizando o <a href="https://www.docker.com/" target="_blank">Docker</a>)
            para iniciar a codificação do software localmente.
          </v-card-text>
          <v-card-text class="pt-4 pb-0 subtitle-1 font-weight-bold">
            Lembre-se: nossos recursos de hardware são limitados e é responsabilidade de todos os empregados o uso consciente!
          </v-card-text>
          <v-card-actions class="py-0 px-6">
            <v-switch
              v-model="accept"
              label="Estou ciente e quero continuar."
              @change="() => {
                if (!accept)
                  reload()
              }"
            />
          </v-card-actions>
        </v-window-item>
        <v-window-item :value="2">
          <v-card outlined color="white">
            <v-card-text>
              <v-form v-model="validate">
                <v-autocomplete
                  outlined
                  label="Escolha um modelo (boilerplate)..."
                  :items="boilerplates"
                  v-model="app.boilerplate"
                  return-object
                  item-text="label"
                  item-value="unix"
                  clearable
                  hide-no-data
                  required
                  @change="loadBoilerplateInfo()"
                >
                  <template slot="selection" slot-scope="data">
                    <v-flex xs2>
                      <v-avatar tile size="40px">
                        <font-awesome-icon color="grey" :icon="data.item.icon" />
                      </v-avatar>
                    </v-flex>
                    <v-flex>
                      {{ data.item.label }}
                    </v-flex>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list three-line width="500">
                      <v-list-item :disabled="data.item.disabled">
                        <v-list-item-avatar tile>
                          <font-awesome-icon color="grey" :icon="data.item.icon" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.label }}</v-list-item-title>
                          <v-list-item-subtitle>{{ data.item.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-autocomplete>

                <v-skeleton-loader transition-group="fade-transition" elevation="2" class="mb-6" type="article@3" v-if="loading" />

                <v-card shaped color="blue darken-2" dark v-if="loaded" class="mb-6" transition-group="fade-transition">
                  <v-card-title>{{ boilerplate.label }}</v-card-title>
                  <v-card-text>{{ boilerplate.description }}</v-card-text>
                  <v-list color="blue darken-2">
                    <v-list-item v-for="(ref, index) in boilerplate.references" :key="index" :href="ref.url" target="_blank">
                      <v-list-item-content>
                        <v-list-item-title>{{ ref.label }}</v-list-item-title>
                        <v-list-item-subtitle>{{ ref.url }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-list color="blue darken-1">
                    <v-subheader>Mantenedores</v-subheader>
                    <v-list-item v-for="(user, index) in boilerplate.maintainers" :key="index">
                      <v-list-item-content>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ user.email }} &bull; {{ user.phone }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-btn icon :href="'https://api.whatsapp.com/send?phone=' + user.phone.replace(/\D/g,'') + '&text=' + encodeURIComponent('Embrapa I/O: Preciso de informações sobre o boilerplate ' + app.boilerplate.unix.toUpperCase() + '!')" target="_blank"><v-icon>phone</v-icon></v-btn>
                        <v-btn icon :href="'mailto:' + user.email" target="_blank"><v-icon>mail</v-icon></v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-card>

                <v-text-field
                  v-if="app.boilerplate"
                  outlined
                  v-model="app.repository"
                  label="Nome Unix do repositório no GitLab"
                  hint="Exemplos: api, mobile, pwa, frontend, backend, ..."
                  :prefix="project.unix + ' /'"
                  :rules="rules"
                  counter="10"
                />
                <v-alert text type="warning" v-show="app.boilerplate && validate">
                  <b>Atenção!</b> Após clicar em "Criar App" seu pedido entrará em uma fila para criação do repositório no
                  <a :href="'https://git.embrapa.io/' + project.unix" target="_blank">grupo do projeto no GitLab</a>.
                </v-alert>
              </v-form>
            </v-card-text>
          </v-card>

          <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-4">
            {{ message }}
          </v-alert>
        </v-window-item>
      </v-window>
      <v-card-actions v-if="step === 1">
        <v-btn
          color="error"
          text
          @click="close()"
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          Cancelar
        </v-btn>

        <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

        <v-btn
          color="primary white--text"
          depressed
          :disabled="!accept || !project.unix.trim().length"
          large
          :block="$vuetify.breakpoint.xsOnly"
          @click="step++"
        >
          Próximo
          <v-icon class="ml-1">
            arrow_forward
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="step === 2 && !$vuetify.breakpoint.xsOnly">
        <v-btn
          color="error"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          color="warning"
          @click="step--"
          text
        >
          <v-icon>arrow_back</v-icon>
          Voltar
        </v-btn>

        <v-btn
          color="success white--text"
          depressed
          :disabled="!accept || !project.unix.trim().length || !app.boilerplate || !validate"
          x-large
          @click="save()"
          :loading="saving"
        >
          Criar App
          <v-icon class="ml-1">
            done
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="step === 2 && $vuetify.breakpoint.xsOnly">
        <v-row wrap>
          <v-col cols="12">
            <v-btn
              color="success white--text"
              depressed
              :disabled="!accept || !project.unix.trim().length || !app.boilerplate || !validate"
              x-large
              @click="save()"
              block
              :loading="saving"
            >
              Criar App
              <v-icon class="ml-1">
                done
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="warning"
              @click="step--"
              text
              block
            >
              <v-icon>arrow_back</v-icon>
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    ErrorHelper
  ],
  data: () => ({
    dialog: false,
    validate: false,
    project: {
      unix: ''
    },
    accept: false,
    step: 1,
    app: {
      repository: '',
      boilerplate: null
    },
    saving: false,
    boilerplates: [],
    rules: [
      v => v.length > 2 || 'Muito curto!',
      v => v.length <= 10 || 'Muito longo!',
      v => !!v || 'Não pode ser vazio!',
      v => !v || /^[a-z0-9][a-z0-9-]+[a-z0-9]$/.test(v) || 'Utilize apenas letras minúsculas, números e hífen!'
    ],
    error: false,
    message: '',
    loading: false,
    loaded: false,
    boilerplate: {
      label: '',
      description: '',
      references: [],
      maintainers: []
    }
  }),
  mounted () {
    if (this.$localStorage.get('user').authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  methods: {
    open (project, boilerplates) {
      this.reload()

      this.boilerplates = boilerplates

      this.project = project

      this.dialog = true
    },
    reload () {
      this.step = 1
      this.accept = false
      this.saving = false
      this.loading = false
      this.loaded = false

      this.app.repository = ''
      this.app.boilerplate = null
    },
    close () {
      this.reload()

      this.dialog = false
    },
    save () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.saving = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.saving = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        const app = {
          repository: this.project.unix + '/' + this.app.repository,
          boilerplate: this.app.boilerplate.unix
        }

        axios.post(api + '/app', app, { headers: this.headers }).then(response => {
          this.saving = false

          this.$emit('message', 'App "' + app.repository + '" criada com sucesso!', 'success')

          this.$emit('refresh')

          this.$emit('close')

          this.reload()

          this.close()
        }).catch(err)
      }).catch(err)
    },
    loadBoilerplateInfo () {
      this.loaded = false

      if (this.app.boilerplate.unix === '_') return

      this.loading = true

      axios.get(process.env.VUE_APP_GITLAB + '/api/v4/projects/io%2Fboilerplate%2F' + this.app.boilerplate.unix + '/repository/files/.embrapa%2Fsettings.json/raw?ref=main')
        .then(response => {
          this.boilerplate = response.data

          setTimeout(() => {
            this.loading = false
            this.loaded = true
          }, 1000)
        })
        .catch(err => {
          this.loading = false

          console.log(err)
        })
    }
  }
}
</script>
