<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height class="px-0 py-0">
        <v-row style="height: 100%;">
          <v-col cols="5" v-show="!$orientation.portrait" class="hidden-sm-and-down" style="background-color: #007937;">
            <v-container fill-height>
              <v-card flat tile dark color="transparent" class="mx-6">
                <img src="@/assets/stamp-white.png" width="30%" class="mx-4 my-6" />
                <v-card-subtitle class="display-1 font-weight-bold my-6">Construa conosco a agropecuária do futuro</v-card-subtitle>
                <v-card-subtitle class="title">Plataforma de desenvolvimento de ativos digitais da Embrapa: aprenda, colabore, desenvolva e distribua as soluções digitais do agro sustentável!</v-card-subtitle>
              </v-card>
            </v-container>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndDown || $orientation.portrait ? '12' : '7'">
            <v-container fill-height justify-center>
              <v-card flat tile color="transparent" width="500px" class="mx-6">
                <img src="@/assets/embrapa.io.png" width="100%" />
                <v-card-title class="display-1 font-weight-black my-6 px-0">
                  <v-icon color="success" large class="mr-2">lock_open</v-icon>
                  Bem vind@!
                </v-card-title>
                <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-0 my-0">
                  {{ message }}
                </v-alert>
                <v-window v-model="step">

                  <v-window-item>
                    <v-card-subtitle class="title px-0">Para começar, digite seu e-mail abaixo:</v-card-subtitle>
                    <v-form ref="form" lazy-validation>
                      <v-text-field
                        v-model="email"
                        append-icon="mail"
                        outlined
                        clearable
                        label="e-Mail"
                        type="text"
                        :rules="rules"
                      />

                      <v-radio-group v-model="reliable" :row="!$vuetify.breakpoint.xsOnly" class="pa-0 ma-0">
                        <template v-slot:label>
                          <div>Este dispositivo é <strong>confiável</strong>?</div>
                        </template>
                        <v-radio
                          label="Sim"
                          :value="true"
                        />
                        <v-radio
                          label="Não"
                          :value="false"
                        />
                        <template v-slot:append>
                          <v-btn text icon small @click="reliableInfo()">
                            <v-icon>help</v-icon>
                          </v-btn>
                        </template>
                      </v-radio-group>
                    </v-form>
                    <v-btn color="success" x-large block @click="sendPin()" :disabled="!validate()" :loading="loading">
                      Prosseguir
                      <v-icon class="ml-2">
                        arrow_forward
                      </v-icon>
                    </v-btn>
                  </v-window-item>

                  <v-window-item>
                    <v-card-subtitle class="title px-0">Um número de 6 dígitos foi enviado para o e-mail <strong>{{ email }}</strong>. Para continuar, insira-o abaixo:</v-card-subtitle>
                    <div class="input-wrapper mb-3" style="width: 280px; margin: 0 auto;">
                      <v-otp-input v-model="pin" length="6" @finish="authenticate()" />
                    </div>
                    <v-card-text v-if="step == 1 && needPrivacy()" class="mt-6 mb-0 py-0">
                      <v-btn color="purple" outlined large @click="showPrivacy()" block>
                        <v-icon class="mr-2">
                          gavel
                        </v-icon>
                        Política de Privacidade
                      </v-btn>
                      <v-switch v-model="agree" label="Li e aceito os termos." class="mt-3 mx-2" />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="success"
                        depressed
                        :disabled="pin.length !== 6 || (needPrivacy() && !agree)"
                        x-large
                        @click="authenticate()"
                        block
                        :loading="loading"
                      >
                        Autenticar
                        <v-icon class="ml-2">
                          done
                        </v-icon>
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions>
                      <v-btn
                        color="error"
                        text
                        @click="cancel()"
                        block
                      >
                        Cancelar
                      </v-btn>
                    </v-card-actions>
                  </v-window-item>

                </v-window>
              </v-card>
            </v-container>
            <v-footer absolute padless color="rgba(255,255,255,0)">
              <v-col :class="$vuetify.breakpoint.smAndDown || $orientation.portrait ? 'text-center' : 'text-right'">
                <v-chip label color="blue-grey lighten-4" text-color="white">
                  <v-icon class="mr-1">
                    update
                  </v-icon>
                  Versão {{ version }}
                </v-chip>
              </v-col>
            </v-footer>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <dialog-wrapper @consent="agree = true" @dissent="agree = false" ref="dPrivacy" />
    <dialog-wrapper ref="dReliable" />
    <beta-wrapper />
  </v-app>
</template>

<script>
import axios from 'axios'
import md5 from 'crypto-js/md5'

import EmbrapaHelper from '@/helpers/embrapa'
import ErrorHelper from '@/helpers/error'

import DialogWrapper from '@/components/DialogDefault'
import BetaWrapper from '@/components/BetaRelease'

const pkg = require('../../package.json')

export default {
  mixins: [
    EmbrapaHelper,
    ErrorHelper
  ],
  components: {
    DialogWrapper,
    BetaWrapper
  },
  data: () => ({
    step: 0,
    email: '',
    rules: [
      v => !v || /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || 'O e-mail precisa ser válido!'
    ],
    pin: '',
    privacy: null,
    agree: false,
    reliable: null,
    loading: false,
    wait: false,
    error: false,
    message: '',
    version: pkg.version
  }),
  mounted () {
    if (this.$localStorage.get('user').authenticated) {
      this.$router.push({ path: '/home' })
    } else {
      const email = this.$localStorage.get('email')

      if (email && email.length > 0) {
        this.reliable = true
        this.email = email
        this.step = 1
      }
    }
  },
  methods: {
    needPrivacy () {
      return !this.isEmbrapa(this.email)
    },
    showPrivacy () {
      const self = this
      axios.get('/privacy-policy.html').then(pol => {
        self.privacy = pol.data
        self.$refs.dPrivacy.open('Política de Privacidade', self.privacy, 'Aceito', 'Não Aceito')
      })
    },
    reliableInfo () {
      const text = '<p>Um <strong>dispositivo confiável</strong> é um equipamento de <u>uso não compartilhado</u> (p.e., <strong>seu celular ou computador pessoal</strong>). Neste caso você permanecerá autenticado neste dispositivo por <u>tempo indeternimado</u>.</p>' +
        '<p>Caso esteja em um equipamento de uso compartilhado, tal como o computador de um saguão de hotel, marque <strong>NÃO</strong> nesta opção! Com isso, ao fechar o navegador, seu usuário será automaticamente deslogado.</p>'

      this.$refs.dPrivacy.open('Dispositivo Confiável', text, 'Ok')
    },
    validate () {
      return this.$refs.form && this.$refs.form.validate() && this.reliable !== null
    },
    cancel () {
      this.$localStorage.set('email', '')

      this.error = false
      this.message = ''

      this.agree = false

      this.email = ''
      this.reliable = null

      this.pin = ''

      this.loading = false

      this.step = 0
    },
    sendPin () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.loading = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.loading = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        axios.post(api + '/pin', { email: this.email, reliable: this.reliable }).then(response => {
          if (this.reliable) this.$localStorage.set('email', this.email)

          this.loading = false

          this.step++
        }).catch(err)
      }).catch(err)
    },
    authenticate () {
      if (this.pin.length !== 6 || (this.needPrivacy() && !this.agree)) return

      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.loading = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.loading = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        axios.post(api + '/authenticate', { email: this.email, pin: this.pin }).then(response => {
          const token = response.data.token

          axios.get(api + '/user', { headers: { Authorization: 'Bearer ' + token } }).then(response => {
            const user = {
              authenticated: true,
              name: response.data.name,
              email: response.data.email,
              picture: '',
              token,
              embrapa: this.isEmbrapa(response.data.email)
            }

            const picture = 'https://www.gravatar.com/avatar/' + md5(response.data.email).toString() + '?s=200&d=404'

            axios.get(picture).then(response => {
              user.picture = picture
            }).catch(err => {
              user.picture = ''
              console.log(err)
            }).finally(() => {
              this.$localStorage.set('user', user)
              this.$localStorage.set('reliable', this.reliable)

              this.$localStorage.set('email', '')

              this.$router.push({ path: '/home' })
            })
          }).catch(err)
        }).catch(err)
      }).catch(err)
    }
  }
}
</script>
