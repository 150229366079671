<template>
  <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title class="teal white--text px-3">
        <span class="headline">Health Check</span>
        <v-spacer />
        <v-btn v-if="$vuetify.breakpoint.xsOnly" icon dark :href="dashboard()" target="_blank" class="mr-2"><v-icon>article</v-icon></v-btn>
        <v-btn v-else elevation="0" color="pink" dark :href="dashboard()" target="_blank" class="mr-4">
          <v-icon class="mr-2">article</v-icon>
          Logs
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.xsOnly" icon dark @click="reload()" class="mr-2"><v-icon>sync</v-icon></v-btn>
        <v-btn v-else elevation="0" color="orange" dark @click="reload()" class="mr-2">
          <v-icon class="mr-2">sync</v-icon>
          Atualizar
        </v-btn>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              dark
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item disabled>
              <v-list-item-icon class="mr-2">
                <v-icon>restore</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Restaurar Backup
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item disabled>
              <v-list-item-icon class="mr-2">
                <v-icon color="error">delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="error--text">
                Remover Build
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-data-table
        :headers="columns"
        :items="services"
        sort-by="role"
        sort-desc
        disable-pagination
        fixed-header
        hide-default-footer
        class="elevation-0"
        light
        :loading="loading"
        no-data-text="Não há informações disponíveis! Aguarde alguns minutos e tente novamente."
      >
        <template v-slot:item.state="props">
          <v-tooltip top attach>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="props.item.state === 'running' ? (props.item.healthy === 'healthy' ? 'success' : 'warning') : 'error'">
                {{ props.item.state === 'running' ? 'bolt' : 'power_off' }}
              </v-icon>
            </template>
            <span>{{ props.item.status }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-card-text class="overline font-italic text-center px-4 pt-0 py-2" v-show="date.trim() !== ''"><b>Importante:</b> Situação verificada em <b class="red--text">{{ date }}</b>!</v-card-text>

      <v-alert :type="this.error ? 'error' : 'success'" :value="error || success" transition="scale-transition" class="mx-4 mt-0">
        {{ message }}
      </v-alert>

      <v-card-text class="pb-0" style="background-color: #eee;">
        <v-row cols="12">
          <v-col cols="10">
            <v-switch v-model="sanitize.value" label="Higienizar/otimizar periodicamente" :disabled="!project.maintainer" :loading="sanitize.loading" inset @click.native.capture="setSanitize($event)"></v-switch>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn text icon small @click="info()" class="my-4">
              <v-icon>help</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pb-2 pt-0" style="background-color: #eee;">
        <v-row wrap>
          <v-col cols="6" class="pr-1">
            <v-btn x-large color="indigo" block disabled>
              <v-icon class="mr-2">terminal</v-icon>
              Console
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn :dark="project.maintainer" x-large color="cyan" :disabled="!project.maintainer" block @click="backup()">
              <v-icon class="mr-2">browser_updated</v-icon>
              Backup
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions class="pb-4 pt-0" style="background-color: #eee;">
        <v-row wrap>
          <v-col cols="6" class="pr-1">
            <v-btn x-large block :dark="project.maintainer" color="blue-grey" :disabled="!project.maintainer" @click="restart()">
              <v-icon class="mr-2">restart_alt</v-icon>
              Reiniciar
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn x-large block color="error" :disabled="!project.maintainer" @click="undeploy()">
              <v-icon class="mr-2">cloud_off</v-icon>
              Desativar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-if="$vuetify.breakpoint.xsOnly">
        <v-row wrap>
          <v-col cols="12">
            <v-btn
              color="error"
              @click="dialog = false"
              outlined
              block
            >
              Fechar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <confirm-wrapper ref="confirm" />
    <dialog-wrapper ref="info" />
  </v-dialog>
</template>

<script>
import axios from 'axios'

import EmbrapaHelper from '@/helpers/embrapa'
import ErrorHelper from '@/helpers/error'

import ConfirmWrapper from '@/components/ConfirmDialog.vue'
import DialogWrapper from '@/components/DialogDefault.vue'

export default {
  mixins: [
    EmbrapaHelper,
    ErrorHelper
  ],
  components: {
    ConfirmWrapper,
    DialogWrapper
  },
  data: () => ({
    dialog: false,
    user: null,
    project: {
      maintainer: false
    },
    app: null,
    stage: null,
    services: [],
    date: '',
    columns: [
      { text: 'Serviço', align: 'start', value: 'service' },
      { text: 'Criado Em', value: 'created', sortable: false },
      { text: 'Status', align: 'center', value: 'state', sortable: false },
      { text: 'Tamanho', value: 'size', sortable: false }
    ],
    headers: {},
    error: false,
    success: false,
    message: '',
    loading: false,
    sanitize: {
      value: false,
      loading: false
    },
    grafana: process.env.VUE_APP_GRAFANA
  }),
  beforeMount () {
    this.user = this.$localStorage.get('user')
  },
  mounted () {
    if (this.user.authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  methods: {
    open (project, app, stage) {
      this.project = project
      this.app = app
      this.stage = stage

      this.reload()

      this.dialog = true
    },
    reload () {
      this.loading = true

      this.error = false
      this.success = false
      this.message = ''

      this.services = []
      this.date = ''
      this.sanitize = {
        value: false,
        loading: false
      }

      const error = error => {
        this.error = true
        this.message = this.errorMessage(error)

        this.loading = false
      }

      axios.get(process.env.VUE_APP_API + '/build/health/' + this.app.repository + '/' + this.stage, { headers: this.headers }).then(response => {
        this.services = response.data.services
        this.date = response.data.date

        axios.get(process.env.VUE_APP_API + '/build/sanitize/' + this.app.repository + '/' + this.stage, { headers: this.headers }).then(response => {
          this.sanitize = {
            value: ['START', 'RUN'].includes(response.data.status),
            loading: response.data.status === 'START'
          }

          this.loading = false
        }).catch(error)
      }).catch(error)
    },
    backup () {
      this.$refs.confirm.open(
        'Executar backup da instância?',
        'Os dados da build "' + this.app.repository + '@' + this.stage + '" (instanciada no servidor "' + this.app.stages[this.stage].cluster + '") serão copiados e compactados — conforme o serviço de backup configurado no orquestrador de containers — e então disponibilizado para os mantenedores (maintainers) do projeto.',
        { color: 'warning', width: 500, persistent: true }).then((confirm) => {
        if (confirm) {
          this.loading = true

          this.error = false
          this.success = false
          this.message = ''

          axios.post(process.env.VUE_APP_API + '/build/backup/' + this.app.repository + '/' + this.stage, {}, { headers: this.headers }).then(response => {
            this.success = true
            this.message = 'Backup iniciado... quando finalizar será enviado um e-mail com instruções para o download. Aguarde!'

            this.loading = false
          }).catch(error => {
            this.error = true
            this.message = this.errorMessage(error)

            this.loading = false
          })
        }
      })
    },
    info () {
      const text = '<p>Marcando esta opção, mensalmente a plataforma irá chamar o serviço "<strong>sanitize</strong>" no <i>stack</i> de containers.</p>' +
        '<p>Ao ativar, será executado imediatamente o processo de higienização/otimização. Um e-mail será enviado aos mantenedores com o resultado e a confirmação do agendamento mensal. Se houver erro, a opção será desativada automaticamente.</p>'

      this.$refs.info.open('Higienizar Periodicamente', text, 'Ok')
    },
    setSanitize (event) {
      const newValue = !this.sanitize.value

      axios.post(process.env.VUE_APP_API + '/build/sanitize/' + this.app.repository + '/' + this.stage + '/' + (newValue ? 'start' : 'stop'), {}, { headers: this.headers }).then(response => {
        this.sanitize.loading = newValue
      }).catch(error => {
        this.error = true
        this.message = this.errorMessage(error)

        this.sanitize.value = !newValue
      })
    },
    undeploy () {
      this.$refs.confirm.open(
        'Desativar instância?',
        'A execução da build "' + this.app.repository + '@' + this.stage + '" (instanciada no servidor "' + this.app.stages[this.stage].cluster + '") será ENCERRADA! Com isso, todos os containers da build serão suspensos (e a aplicação ficará OFFLINE), mas continuarão no servidor (podendo ser reativados a qualquer momento). Nenhum dado será perdido.',
        { color: 'error', width: 500, persistent: true }).then((confirm) => {
        if (confirm) {
          this.loading = true

          this.error = false
          this.success = false
          this.message = ''

          axios.post(process.env.VUE_APP_API + '/build/undeploy/' + this.app.repository + '/' + this.stage, {}, { headers: this.headers }).then(response => {
            this.loading = false

            this.$emit('refresh')

            this.dialog = false
          }).catch(error => {
            this.error = true
            this.message = this.errorMessage(error)

            this.loading = false
          })
        }
      })
    },
    restart () {
      this.$refs.confirm.open(
        'Reiniciar a instância?',
        'A execução da build "' + this.app.repository + '@' + this.stage + '" (instanciada no servidor "' + this.app.stages[this.stage].cluster + '") será REINICIADA!',
        { color: 'error', width: 500, persistent: true }).then((confirm) => {
        if (confirm) {
          this.loading = true

          this.error = false
          this.success = false
          this.message = ''

          axios.post(process.env.VUE_APP_API + '/build/restart/' + this.app.repository + '/' + this.stage, {}, { headers: this.headers }).then(response => {
            this.success = true
            this.message = 'Reinício agendado... agora é só aguardar!'

            this.loading = false
          }).catch(error => {
            this.error = true
            this.message = this.errorMessage(error)

            this.loading = false
          })
        }
      })
    },
    dashboard () {
      if (this.app === null) return this.grafana

      const pieces = this.app.repository.split('/')

      const project = pieces[0].substring(0, 20)
      const app = pieces[1].substring(0, 10)

      return this.grafana + '/d/' + project + '_' + app + '_' + this.stage
    }
  }
}
</script>
